/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import "../../assets/styles/normalize.css"
import "../../assets/styles/global.scss"
import * as layoutStyles from './layout.module.scss'
import Header from "./header"
import CustomerContextProvider from '../../contexts/Customer'
import AuthContextProvider from '../../contexts/Auth'

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div className="BigCommerceApp">
      <AuthContextProvider>
        <CustomerContextProvider>
          <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
          <main className={`container ${layoutStyles.main}`}>{children}</main>
        </CustomerContextProvider>
      </AuthContextProvider>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
