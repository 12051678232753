import React, { Component, useContext, useEffect } from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

import { CustomerContext } from '../../contexts/Customer'
import { AuthContext } from '../../contexts/Auth'
import * as HeaderStyles from './header.module.scss'


const Header = ({ siteTitle }) => {
  const { name: customerName, setName } = useContext(CustomerContext)
  // useEffect(() => {
  //   setTimeout(() => {
  //     setName('Another guy')
  //   }, 5000);
  // }, [ customerName ] )

  return (
    <header>
      <nav className="container">
        <Link to="/">{siteTitle}</Link>
        <div className={HeaderStyles.currentCustomer}>
          Welcome, {customerName}
        </div>
      </nav>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header;



// class Header extends Component {
//   render() { 
//     console.log('Header props', this.props);
//     return (
//       <header>
//         <nav className="container">
//           <Link to="/">{this.props.siteTitle}</Link>
//           <div className={HeaderStyles.currentCustomer}>
//             Welcome, {this.props.customerContext.name}
//           </div>
//         </nav>
//       </header>
//     );
//   }
// }
 
// const headerWithContext = (Component) => (
//   props => {
//     return (
//       <AuthContext.Consumer>{(authContext) => (
//         <CustomerContext.Consumer>{(customerContext) => {
//           return (
//             <Component authContext={authContext} customerContext={customerContext} {...props} />
//           )
//         }}</CustomerContext.Consumer>
//       )}</AuthContext.Consumer>
//     )
//   }
// )

// export default headerWithContext(Header)
