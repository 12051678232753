import React, { createContext, Component } from 'react';

export const CustomerContext = createContext()

// Async reducer
// https://medium.com/@joseph.michael.sample/async-actions-with-usereducer-d75a2c5dc55c

var externalData;
async function loadCustomerData() {
  return new Promise((resolve, reject) => {
    if (externalData) {
      resolve(externalData)
    }
    else {
      setTimeout(() => {
        externalData = { name: 'Pavel Reva' }
        resolve(externalData)
      }, 3000);
    }
  })
}

class CustomerContextProvider extends Component {

  constructor(props) {
    super(props);

    loadCustomerData().then((data) => {
      this.setState({
        name: data.name
      })
    });
  }

  state = {
    name: ''
  }

  setName = (name) => {
    this.setState({name})
  }

  render() {
    return (
      <CustomerContext.Provider value={{...this.state, setName: this.setName}}>
        {this.props.children}
      </CustomerContext.Provider>
    );
  }
}
 
export default CustomerContextProvider;